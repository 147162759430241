import React from 'react'
import { Form, Container, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

function DemoFormMobile({onSubmit, form}) {
    const { t } = useTranslation();

  return (
    <Form onSubmit={onSubmit} ref={form} id="demo-form=mobile">
        <Container>
            <Row>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>{t('Name')}</Form.Label>
                        <Form.Control type="text" name="user_name" required autoFocus/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="jobtitle">
                        <Form.Label>{t('Job Title')}</Form.Label>
                        <Form.Control type="text" name="job_title" required/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="email" >
                        <Form.Label>{t('Email address')}</Form.Label>
                        <Form.Control type="email" name="user_email" required />
                        <Form.Text className="text-muted">
                        {t('We\'ll never share your email with anyone else.')}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="contact">
                        <Form.Label>{t('Contact Number')}</Form.Label>
                        <Form.Control type="number" name="user_contact" required/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="company_name">
                        <Form.Label>{t('Company Name')}</Form.Label>
                        <Form.Control type="text" name="company_name" required/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="company_address">
                        <Form.Label>{t('Company Address')}</Form.Label>
                        <Form.Control as="textarea" rows={3} name="company_address" required/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="company_size">
                        <Form.Label>{t('Company Size')}</Form.Label>
                        <Form.Select aria-label="size-select" name="company_size" required>
                            <option>Select size</option>
                            <option value="1-50">1-50</option>
                            <option value="51-200">51-200</option>
                            <option value="200-1000">200-1,000</option>
                            <option value="1001+">1,001+</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="service">
                        <Form.Label>{t('Service of interest')}</Form.Label>
                        <Form.Select aria-label="service-select" name="service" required>
                            <option>Select a service</option>
                            <option value="Dmeets">Dmeets</option>
                            <option value="DTime">DTime</option>
                            <option value="SME">SME by DCM</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="message">
                        <Form.Label>{t('Additional Message')}</Form.Label>
                        <Form.Control as="textarea" rows={3} required name="message"/>
                    </Form.Group>
            </Row>
          </Container>
    </Form>
  )
};
export default DemoFormMobile;