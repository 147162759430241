let eventGuid = 0
// let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
    // JAN 2024
    {
      id: createEventId(),
      start: new Date("2024-01-08T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "WHT",
      description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
      color: '#748DA6'
    },
    {
      id: createEventId(),
      start: new Date("2024-01-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "WHT",
      description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
      ยื่นแบบทางอินเทอร์เน็ต / E-filing
      (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
      (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
      color: '#748DA6'
    },
    {
      id: createEventId(),
      start: new Date("2024-01-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "VAT / SBT",
      description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
      (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
      color:'#68A7AD'
    },
    {
      id: createEventId(),
      start: new Date("2024-01-31T12:00:00Z").toISOString().replace(/T.*$/, '') ,
      title: "WHT",
      description: `ยื่นรายการภาษีเงินได้หัก ณ ที่จ่ายประจำปี พ.ศ. 2566 (ภ.ง.ด. 2ก, ภ.ง.ด. 3ก) สำหรับมาตรา 40 (4)(5)(6)(7)(8)
      Annual Withholding Tax Return for 2023 (Por.Ngor.Dor. 2A, Por.Ngor.Dor. 3A) for Article 40 (4)(5)(6)(7)(8)`,
      color: '#748DA6'
    },
  //FEBRUARY 2024
  {
    id: createEventId(),
    start: new Date("2024-02-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-02-08T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `ยื่นแบบทางอินเทอร์เน็ต
    ยื่นรายการภาษีเงินได้หัก ณ ที่จ่ายประจำปี พ.ศ. 2566 (ภ.ง.ด. 2ก, ภ.ง.ด. 3ก) สำหรับมาตรา 40 (4)(5)(6)(7)(8)
    Annual Withholding Tax Return for 2023 (Por.Ngor.Dor. 2A, Por.Ngor.Dor. 3A) for Article 40 (4)(5)(6)(7)(8)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-02-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
    ยื่นแบบทางอินเทอร์เน็ต 
    (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-02-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-02-29T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `ยื่นนรายการภาษีเงินได้หัก ณ ที่จ่ายประจำปี พ.ศ. 2566 (ภ.ง.ด. 1ก และ ภ.ง.ด. 1ก พิเศษ) สำหรับมาตรา 40 (1)(2)
    Annual Withholding Tax Return for 2023 (Por.Ngor.Dor. 1A, Por.Ngor.Dor. 1A Special) for Article 40 (1)(2)`,
    color: '#748DA6'
  },
  //MARCH
  {
    id: createEventId(),
    start: new Date("2024-03-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-03-08T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `ยื่นแบบทางอินเทอร์เน็ต
    ยื่นรายการภาษีเงินได้หัก ณ ที่จ่ายประจำปี พ.ศ. 2566 (ภ.ง.ด. 1ก ) สำหรับมาตรา 40 (1)(2)
    Annual Withholding Tax Return for 2023 (Por.Ngor.Dor. 1A) for Article 40 (1)(2)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-03-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: ` (รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
    ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-03-25T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
    color:'#68A7AD'
  },
  //APRIL
  {
    id: createEventId(),
    start: new Date("2024-04-01T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "PIT",
    description: `ยื่นแบบแสดงรายการภาษีเงินได้บุคคลธรรมดาประจำปี พ.ศ. 2565 (ภ.ง.ด. 90, 91, 95)
    Annual Personal Income Tax Return for 2022 (Por.Ngor.Dor. 90, 91, 95)\n
    ยื่นแบบแสดงรายการภาษีป้าย (ภ.ป. 1)
    Signboard Tax Return (Phor.Por. 1)`,
    color:'#92B4EC'
  },
  {
    id: createEventId(),
    start: new Date("2024-04-09T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)
    ยื่นแบบแสดงรายการภาษีเงินได้บุคคลธรรมดาประจำปี พ.ศ. 2565 (ภ.ง.ด. 90, 91, 95)
    Annual Personal Income Tax Return for 2022 (Por.Ngor.Dor. 90, 91, 95)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-04-17T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
    ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-04-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
    color:'#68A7AD'
  },
  //MAY
  {
    id: createEventId(),
    start: new Date("2024-05-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นeส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54,Por.Por. 36)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-05-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
    ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-05-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-05-29T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "CIT",
    description: `(สำหรับนิติบุคคลที่มีรอบระยะเวลาบัญชีเริ่มต้นและสิ้นสุด 1 มกราคม - 31 ธันวาคม)
    ยื่นแบบแสดงรายการภาษีเงินได้นิติบุคคลประจำปี พ.ศ. 2565 (ภ.ง.ด. 50, 52, 55)
    แบบรายงานประจำปี (Disclosure Form) สำหรับบริษัทหรือห้างหุ้นส่วนนิติบุคคลที่มีความสัมพันธ์กันตาม ม. 71 ทวิ
    (for the juristic person having the fiscal year starting from January 1-ending December 31 )
    Annual Corporate Income Tax Return for 2022 (Por.Ngor.Dor. 50, 52, 55)
    Disclusure Form for a company or juristic partnership that are related, under Section 71 Bis`,
    color:'#9A86A4'
  },
  //JUNE
  {
    id: createEventId(),
    start: new Date("2024-06-06T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "CIT",
    description: `(สำหรับนิติบุคคลที่มีรอบระยะเวลาบัญชีเริ่มต้นและสิ้นสุด 1 มกราคม - 31 ธันวาคม)
    ยื่นแบบแสดงรายการภาษีเงินได้นิติบุคคลประจำปี พ.ศ. 2565 (ภ.ง.ด. 50, 52, 55)
    แบบรายงานประจำปี (Disclosure Form) สำหรับบริษัทหรือห้างหุ้นส่วนนิติบุคคลที่มีความสัมพันธ์กันตาม ม. 71 ทวิ
    (for the juristic person having the fiscal year starting from January 1-ending December 31 )
    Annual Corporate Income Tax Return for 2022 (Por.Ngor.Dor. 50, 52, 55)
    Disclusure Form for a company or juristic partnership that are related, under Section 71 Bis`,
    color:'#9A86A4'
  },
  {
    id: createEventId(),
    start: new Date("2024-06-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-06-17T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
    ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-06-24T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
    color:'#68A7AD'
  },
  //JULY
  {
    id: createEventId(),
    start: new Date("2024-07-01T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "LBT",
    description: `ยื่นรายการภาษีที่ดินและสิ่งปลูกสร้าง ประจำปี พ.ศ. 2567
    Payment of Land and Building Tax of 2024`,
    color:'#B1BCE6'
  },
  {
    id: createEventId(),
    start: new Date("2024-07-08T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-07-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
    ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-07-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
    color:'#68A7AD'
  },
  //AUGUST
  {
    id: createEventId(),
    start: new Date("2024-08-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-08-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
    ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-08-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
    color:'#68A7AD'
  },
  //SEPTEMBER
  {
    id: createEventId(),
    start: new Date("2024-09-02T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "CIT",
    description: `ยื่นแบบแสดงรายการภาษีเงินได้นิติบุคคล (ภ.ง.ด. 51) สำหรับนิติบุคคลที่มีรอบระยะเวลาบัญชีเริ่มต้น 1 มกราคม สิ้นสุด 31 ธันวาคม
    Semi-Annual Corporate Income Tax Return (Por.Ngor.Dor. 51) for the juristic person having the fiscal year starting from January 1 - ending December 31`,
    color:'#9A86A4'
  },
  {
    id: createEventId(),
    start: new Date("2024-09-09T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    ยื่นแบบแสดงรายการภาษีเงินได้นิติบุคคล (ภ.ง.ด. 51) สำหรับนิติบุคคลที่มีรอบระยะเวลาบัญชีเริ่มต้น 1 มกราคม สิ้นสุด 31 ธันวาคม
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)
    Semi-Annual Corporate Income Tax Return (Por.Ngor.Dor. 51) for the juristic person having the fiscal year starting from January 1-ending December 31`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-09-16T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
    ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-09-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-09-30T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "PIT",
    description: `ยื่นรายการภาษีเงินได้บุคคลธรรมดาครึ่งปี (ภ.ง.ด. 94) สำหรับมาตรา 40 (5)(6)(7)(8)
    Semi-Annual Personal Income Tax Return (Por.Ngor.Dor. 94) for Article 40(5)(6)(7)(8)`,
    color:'#92B4EC'
  },
  // OCTOBER
  {
    id: createEventId(),
    start: new Date("2024-10-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-10-08T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `ยื่นแบบทางอินเทอร์เน็ต
    ยื่นรายการภาษีเงินได้บุคคลธรรมดาครึ่งปี (ภ.ง.ด. 94) สำหรับมาตรา 40 (5)(6)(7)(8)
    Semi-Annual Personal Income Tax Return (Por.Ngor.Dor. 94) for Article 40(5)(6)(7)(8)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-10-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
    ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-10-24T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
    color:'#68A7AD'
  },
  // NOVEMBER
  {
    id: createEventId(),
    start: new Date("2024-11-07T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-11-15T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
    ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-11-25T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
    color:'#68A7AD'
  },
  // DECEMBER
  {
    id: createEventId(),
    start: new Date("2024-12-09T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "WHT",
    description: `(รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color: '#748DA6'
  },
  {
    id: createEventId(),
    start: new Date("2024-12-16T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)\n
    ยื่นแบบทางอินเทอร์เน็ต
    (รายเดือน) ยื่นรายการภาษีเงินได้หัก ณ ที่จ่าย / นำส่งภาษีมูลค่าเพิ่ม (ภ.ง.ด. 1, 2, 3, 53, 54, ภ.พ. 36)
    (Monthly) Withholding Income Tax Return / VAT Remittance Return (Por.Ngor.Dor. 1, 2, 3, 53, 54, Por.Por. 36)`,
    color:'#68A7AD'
  },
  {
    id: createEventId(),
    start: new Date("2024-12-23T12:00:00Z").toISOString().replace(/T.*$/, '') ,
    title: "VAT / SBT",
    description: `(รายเดือน) ยื่นแบบภาษีมูลค่าเพิ่ม และ/หรือ ภาษีธุรกิจเฉพาะ (ภ.พ. 30, ภ.ธ. 40)
    (Monthly) VAT and/or SBT Tax Return (Por.Por. 30, Por.Thor. 40)`,
    color:'#68A7AD'
  }
]

export function createEventId() {
  return String(eventGuid++)
}
