import React from 'react';
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import HomepageCarousel from '../../Components/HomepageCarousel/HomepageCarousel';
import './Homepage.css';
import webbar from '../../Assets/DCMWebBar_Mobile.jpeg';

function mobileContent() {
    return (
        <div className='mobile-content'>
            <img className="dcm-logo" src={webbar} alt="mobile-webbar" />
        </div>
    );
}

function Homepage() {
    const { t } = useTranslation();

    return (
        <div className='homepage-component'>
            {isMobile ?  mobileContent() : <HomepageCarousel />}
            <div className='digital-component'>
                <div className='top-blue-line'/>
                    <h2 className='homepage-heading'>
                        {t('Your SME Digital Mate')}
                    </h2>
                <div className='bottom-blue-line'/>
            </div>
        </div>
    );
}

export default Homepage;