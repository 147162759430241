import React from 'react';
import { useTranslation } from "react-i18next";
import './Services.css';
import { Container, Row, Col } from 'react-bootstrap';
import {Link} from "react-router-dom";
import services_logo from '../../Assets/dcm-services.PNG';
import dmeets_logo from '../../Assets/DMEETS.PNG';
import sme_logo from '../../Assets/SME.PNG';
import dtime_logo from '../../Assets/DTIME.PNG';


function Services() {
    const { t } = useTranslation();

    return (
        <div className='service-wrapper'>
            <Container className='service-container'>
                <Row className='service-row'>
                    <Col className='service-col'>
                        <h1 className='service-heading'>{t('Services')}</h1>
                        <h5 className='service-details'>
                            {t('At DCM, we never stop developing to provide full-service software for all your business needs. We help our customers build and maintain their businesses with our customizable applications and offer the necessary support. This is our way of striving for a higher level of service.')}
                        </h5>
                        <h5 className='service-details' style={{ fontWeight: "bold" }} >
                            {t('Keeping your business on track.')}
                        </h5>
                        <h5 className='service-details'>
                            {t('We aim to make maintaining your business as seamless as possible with our software applications to support all business operations.')}
                        </h5>
                        <h5 className='service-details'>
                            {t('Unlike ordinary demanding business procedures, our agile software applications can help you significantly reduce business restrictions and manage your time.')}
                        </h5>
                    </Col>
                    <Col className='image-col'>
                        <img className="service-logo" src={services_logo} alt="services-logo" />
                    </Col>
                </Row>
                <div className='divider' />
                <Row className='service-app-row' id="dmeets">
                    <Col className='app-col'>
                        <img className="app-logo" src={dmeets_logo} alt="dmeets-logo" />
                        <div className='app-wrapper'>
                            <h2 className='app-heading'>{t('Dmeets')}</h2>
                            <h5 className='app-details'>
                                {t('Keeps your online meeting safe and secure with our cloud data. Be the host or join a meeting.')}
                            </h5>
                            <h5 className='app-details'>
                                {t('It\'s simple and easy, plus it\'s free to use.')}
                            </h5>
                            <h5 className='app-details'>
                                {t('Visit our dmeets ')}
                                <a className="app-link" href="https://dmeets.dcm.co.th/">{t('wesbite')}</a>
                                {t(' for more information.')}
                            </h5>
                        </div>
                    </Col>
                </Row>
                <div className='divider' />
                <Row className='service-app-row' id="sme">
                    <Col className='app-col'>
                        <div className='app-wrapper'>
                            <h2 className='app-heading-right'>{t('SME Mate')}</h2>
                            <h5 className='app-details-right'>
                                {t('Empower your SME success: streamline document management and operations with our all-in-one platform.')}
                            </h5>
                            <h5 className='app-details-right'>
                                {t('Manage all your work with us via our website and mobile application.')}
                            </h5>
                            {/* <h5 className='app-details-right'>
                                {t('Click here to register with us.')}
                            </h5> */}
                        </div>
                        <img className="app-logo-right" src={sme_logo} alt="sme-logo" />
                    </Col>
                </Row>
                <div className='divider' />
                <Row className='service-app-row' id="dtime">
                    <Col className='app-col'>
                        <img className="app-logo" src={dtime_logo} alt="dtime-logo" />
                        <div className='app-wrapper'>
                            <h2 className='app-heading'>{t('DTime')}</h2>
                            <h5 className='app-details'>
                                {t('Web or mobile, the choice is yours: track your time with our versatile platform.')}
                            </h5>
                            <h5 className='app-details'>
                                {t('A variety of options are available to fit your requirements.')}
                            </h5>
                            <h5 className='app-details'>
                                <Link to='/contact-us'>{t('Contact')}</Link>
                                {t(' us today for more information.')}
                            </h5>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Services;