import React from 'react';
import { useTranslation } from "react-i18next";
import './AboutDCM.css';
import { Container, Row, Col } from 'react-bootstrap';
import logo from '../../Assets/DCM_LOGO.PNG';

function AboutDCM() {
    const { t } = useTranslation();

    return (
        <div className='about-wrapper'>
            <Container className='about-container'>
                <Row className='about-row'>
                    <Col className='about-col'>
                        <h1 className='about-heading'>{t('About DCM')}</h1>
                        <h5 className='about-details'>
                            {t('Empower your business with tailored software solutions. We specialize in developing, building, and designing standard software for SMEs, then customizing it to your specific needs. Save time managing everyday tasks and gain control with features that perfectly fit your workflow.')}
                        </h5>
                    </Col>
                    <Col className='image-col'>
                        <div className='square' />
                        <img className="logo" src={logo} alt="Logo" />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default AboutDCM;