import React from 'react';
import { useTranslation } from "react-i18next";
import './ContactUs.css';
import { Container, Row, Col } from 'react-bootstrap';
import ContactForm from '../../Components/ContactForm/ContactForm';

function ContactUs() {
    const { t } = useTranslation();

    return (
        <div className='contactus-wrapper'>
            <Container className='contactus-container'>
                <Row className='contact-row'>
                    <Col className='contact-col'>
                        <h1 className='contact-heading'>{t('Contact DCM')}</h1>
                        <h5 className='contact-details'>
                            {t('Address: ')}{t('942/69 Charn Issara Tower 2nd Floor Rama IV Road, Suriya Wong, Bang Rak, Bangkok 10500, Thailand')}
                        </h5>
                        <h5 className='contact-details'>
                            {t('Contact Number: ')}(66) 2233-0055, (66) 2233-0068
                        </h5>
                        <h5 className='contact-details'>
                            {t('Email Address: ')}admin@dcm.co.th
                        </h5>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ContactForm />
                    </Col>
                    <Col>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.8082351059056!2d100.529617233727!3d13.730056970676168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29f2bc2033193%3A0x245a54406c70d286!2sCharn%20Issara%20Tower%201!5e0!3m2!1sen!2sth!4v1670505233182!5m2!1sen!2sth"
                            className='map'
                            style={{ border: "0" }} 
                            allowFullScreen="" 
                            loading="lazy" 
                            referrerPolicy="no-referrer-when-downgrade"
                            title="myFrameMap"
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ContactUs;